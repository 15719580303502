<template>
  <div class="col working_together_wrapper just_label_in_cards_override">
    <card>
      <!-- Start Why this is important  -->
      <div class="importance_wrapper">
        <h4
          class="main_title_from_cms"
          :class="{ 'text-right': isRtl() }"
          v-if="team_pages_titles_content[2]"
          v-html="team_pages_titles_content[2].body"
        ></h4>
        <div
          :class="{ 'text-right': isRtl() }"
          v-if="team_pages_content[2]"
          v-html="team_pages_content[2].body"
        ></div>
      </div>
      <!-- End Why this is important  -->
    </card>

    <!-- Start Choose Teams  -->
    <card>
      <!-- Start Header -->
      <div
        class="header_contact_wrapping mb-4"
        :class="{ 'd-flex flex-row-reverse': isRtl() }"
      >
        <h4 class="card-title" :class="{ 'd-flex flex-row-reverse': isRtl() }">
          {{ localization("Choose Team") }}
        </h4>
      </div>
      <!-- End Header -->

      <!-- Start Team Select Box  -->
      <div class="col-md-4">
        <el-select
          v-model="team_id"
          placeholder="Please select team"
          style="width: 100%"
          @change="handlerTeamData(team_id)"
        >
          <el-option
            v-for="team in allTeamsList"
            :key="team.id"
            :label="team.name"
            :value="team.id"
          ></el-option>
        </el-select>
      </div>
      <!-- End Team Select Box  -->

      <!-- Start Team Description  -->
      <div v-if="everyTeamData" class="mt-3">
        <h4
          v-if="everyTeamData.team"
          class="card-title with_border"
          :class="{ 'd-flex flex-row-reverse': isRtl() }"
        >
          {{ localization("Team Description") }}
        </h4>
        <p class="decs_text" v-if="everyTeamData.team">
          {{ everyTeamData.team.classification }}
        </p>
      </div>
      <!-- End Team Description  -->

      <!-- Start Team Members -->
      <div class="team_members_wrapper" v-if="everyTeamData.team_users">
        <el-collapse accordion>
          <el-collapse-item
            v-for="member in everyTeamData.team_users"
            :key="member.id"
            :title="`${member.first_name} ${member.last_name}`"
            :name="member.id"
          >
            <!-- Start Header   -->
            <template slot="title">
              <div class="wrapper_collapse_for_header">
                <div class="head_part_one">
                  <div class="part_a">
                    <span class="d-block user-name">
                      {{ member.first_name }} {{ member.last_name }}
                    </span>
                  </div>
                  <div class="part_b">
                    {{ member.classification }}
                  </div>
                </div>
              </div>
            </template>
            <!-- End Header  -->
            <!-- Start LCI Scores  -->
            <div
              class="
                    flex-grow-1 flex-wrap
                    d-flex
                    justify-content-around
                    align-items-center
                    text-capitalize
                    main-scores-container-sm main-scores-container
                  "
            >
              <div class="d-flex align-items-center every-score">
                <span class="d-block score-word">{{ $t("patterns.S") }}</span>
                <span class="label sequence d-block ">
                  {{ member.sequence != null ? member.sequence : 0 }}
                </span>
              </div>
              <div class="d-flex align-items-center every-score">
                <span class="d-block score-word">{{ $t("patterns.P") }}</span>
                <span class="label precision d-block ">
                  {{ member.precision != null ? member.precision : 0 }}
                </span>
              </div>
              <div class="d-flex align-items-center every-score">
                <span class="d-block score-word">{{ $t("patterns.TR") }}</span>
                <span class="label technical_reasoning d-block ">
                  {{
                    member.technical_reasoning != null
                      ? member.technical_reasoning
                      : 0
                  }}
                </span>
              </div>
              <div class="d-flex align-items-center every-score">
                <span class="d-block score-word">{{ $t("patterns.C") }}</span>
                <span class="label confluence d-block ">
                  {{ member.confluence != null ? member.confluence : 0 }}
                </span>
              </div>
            </div>
            <!-- Start LCI Scores  -->

            <!-- Start Introduce Yourself  -->
            <div class="introduce_yourself_wrapper">
              <div
                class="every_intro"
                v-for="(item, index) in member.user_statements"
                :key="index"
              >
                <!-- <div> -->
                <span class="question_title d-block"> {{ index }}</span>
                <ul
                  class="patterns_wrapper"
                  v-for="(pattern, i) in item"
                  :key="i"
                >
                  <li>
                    <span
                      class="pattern_title"
                      :style="`color: ${getProgressColor(i)}`"
                    >
                      <i
                        :class="`fa fa-star mr-1 ${i}`"
                        :style="`color: ${getProgressColor(i)}`"
                      ></i>
                      {{ i }}
                    </span>
                    <div v-for="(pat, i) in pattern" :key="i">
                      <div class="for_avoid_etc_words">{{ i }}</div>
                      <ul class="intro_list" v-for="item in pat" :key="item.id">
                        <li class="every_item">
                          {{ item.statement }}
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
                <!-- </div> -->
              </div>
            </div>
            <!-- End Introduce Yourself  -->

            <!-- Start Statements  -->
            <!-- v-for="pattern in member.pattern_statements"
                :key="pattern.id" -->
            <div class="statements_wrapper">
              <div class="just_for_wrapper_icon">
                <div class="all_statements">
                  <!-- Sequence -->
                  <div
                    class="every_statement "
                    v-if="member.pattern_statements != null"
                  >
                    <span class="state_name sec d-block">
                      Statement
                    </span>
                    <div class="state_words d-block">
                      {{ member.pattern_statements.statement }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- Start User Without Statement  -->
              <div
                v-if="member.pattern_statements == null"
                class="without_statement"
              >
                <div class="st_words d-block">
                  No Statement Available
                </div>
              </div>
              <!-- End User Without Statement  -->
            </div>
            <!-- End Statements  -->
          </el-collapse-item>
        </el-collapse>
      </div>
      <!-- End Team Members  -->
    </card>
    <!-- End Choose Teams  -->

    <!-- Start Team Line Graph  -->
    <card v-if="everyTeamMembers.length > 0">
      <div class="the_main_wrapper_for_charts" v-if="!loadingValue">
        <div class="is_chart_wrapper">
          <chart-card
            v-if="scores.length > 0"
            :chart-data="teamChart"
            :chart-options="barChart.options"
            :chart-responsive-options="barChart.responsiveOptions"
            ref="chart"
          >
            <template slot="header">
              <div class="d-flex">
                <i
                  class="el-icon el-icon-data-analysis"
                  style="font-size: 30px; margin-right: 7px"
                ></i>
                <h4 class="card-title">
                  {{ localization("Team Line Graph") }}
                </h4>
              </div>
            </template>
          </chart-card>
          <div class="lines_in_chart avoid">
            <span class="is_text_chart">Avoid</span>
          </div>
          <div class="lines_in_chart needed">
            <span class="is_text_chart">As Needed</span>
          </div>
          <div class="lines_in_chart first">
            <span class="is_text_chart">Use First</span>
          </div>
        </div>
        <div class="for_line_graph_members_container">
          <div
            v-for="(member, index) in everyTeamMembers"
            :key="member.id"
            class="every_bullet_wrapper"
            :class="`member_${index + 1}`"
          >
            <span class="bullet"></span>
            <span class="text-capitalize"
              >{{ member.first_name }} {{ member.last_name }}
            </span>
          </div>
        </div>
      </div>
    </card>
    <!-- End Team Line Graph  -->
    <card v-if="everyTeamData.team_users">
      <h4 class="card-title" :class="{ 'd-flex flex-row-reverse': isRtl() }">
        {{ localization("Team Statements") }}
      </h4>
      <div class="list_of_new_statements_team" v-if="everyTeamData.team_users">
        <div
          class="shadow-sm every_list_of_new"
          v-for="member in everyTeamData.team_users"
          :key="member.id"
        >
          <div class="text-capitalize">
            <span>{{ member.first_name }} {{ member.last_name }}</span>
          </div>
          <div class="is_state_text">
            <span v-if="member.pattern_statements != null">{{
              member.pattern_statements.statement
            }}</span>
            <span v-else>No Statement Available</span>
          </div>
        </div>
      </div>
    </card>

    <!-- Start Add Statements  -->
    <card>
      <!-- <template #header> -->
      <!-- Start Header -->
      <div
        class="header_contact_wrapping mb-4"
        :class="{ 'd-flex flex-row-reverse': isRtl() }"
      >
        <h4
          class="card-title text-capitalize"
          :class="{ 'd-flex flex-row-reverse': isRtl() }"
        >
          {{ localization("How i will work with this team?") }}
        </h4>
      </div>
      <!-- End Header -->

      <!-- Start Add Statements Form -->
      <el-form
        :model="user"
        :rules="personalStatementsRules"
        ref="personalStatementsRef"
        :class="{ 'text-right build_with_rtl': isRtl() }"
      >
        <el-row class="pt-2" :gutter="20">
          <el-col :sm="24">
            <el-row
              class="pt-2"
              :gutter="20"
              :class="{ 'd-flex flex-row-reverse': isRtl() }"
            >
              <!-- Start Statement  -->
              <el-col :sm="12" class="my-2">
                <el-form-item
                  :label="`${localization('Statement')}`"
                  prop="statement"
                >
                  <el-input v-model="user.statement" />
                  <span class="d-block notes_text">
                    {{ localization("working together statement") }}
                  </span>
                </el-form-item>
              </el-col>
              <!-- End Statement  -->
            </el-row>
          </el-col>

          <el-col :span="24">
            <div
              class="dialog_footer"
              :class="{ 'justify-content-start': isRtl() }"
            >
              <el-button
                class="text-center text-capitalize save_button"
                @click="saveStatements"
              >
                {{ localization("Save") }}</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-form>
      <!-- End Add Statements Form -->
      <!-- </template> -->
      <div
        class="team_routes_wrapper"
        :class="{ 'd-flex flex-row-reverse': isRtl() }"
      >
        <router-link class="back_link" to="/teams"
          ><i class="fa fa-arrow-left back_icon mr-1"></i>
          {{ localization("Back to team") }}
        </router-link>
        <router-link class="back_link" to="/tackling-project">
          {{ localization("next") }}
          <i class="fa fa-arrow-right back_icon mr-1"></i>
        </router-link>
      </div>
    </card>
    <!-- End Add Statements  -->
  </div>
</template>

<script>
import { Card, Table as LTable } from "src/components/index";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { ChartCard, StatsCard } from "src/components/index";
export default {
  data() {
    return {
      personalStatementsRules: {
        statement: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      user: {
        statement: null
        // sequence_statement: null,
        // precision_statement: null,
        // technical_reasoning_statement: null,
        // confluence_statement: null
      },
      team_id: null,
      member_id: null,
      everyTeamData: {},
      everyTeamMembers: [],
      options: {
        low: 0,
        showArea: true,
        height: "350px",
        axisX: {
          showGrid: true
        },
        lineSmooth: true,
        showLine: true,
        showPoint: true
      },
      barChart: {
        options: {
          seriesBarDistance: 20,
          showPoint: true,
          labelDirection: "explode",
          chartPadding: 0,
          labelOffset: 20,
          low: 7,
          high: 40,
          showLine: true,
          labelInterpolationFnc: function(value) {
            return `${value.split(" ")[0]}...`;
          },
          height: "350px",
          width: "95%",
          axisX: {
            offset: 30,
            position: "end",
            showLabel: true,
            showGrid: true,
            labelOffset: {
              x: 0,
              y: 5
            }
          },
          axisY: {
            offset: 50,
            position: "start",
            showLabel: true,
            showGrid: true,
            scaleMinSpace: 20,
            onlyInteger: true,
            labelOffset: {
              x: 0,
              y: 0
            }
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },
      handlerScores: [],
      teamChartTwo: {}
    };
  },
  mounted() {
    this.$store.dispatch("user/get_all_teams");
    this.$store.dispatch("user/get_all_teams_content");
  },
  components: {
    Card,
    LTable,
    ChartCard,
    StatsCard
  },
  computed: {
    ...mapGetters({
      loadingValue: ["user/usersLoad"],
      team_pages_content: ["user/get_team_pages_content"],
      team_pages_titles_content: ["user/get_team_pages_titles_content"],
      allTeamsList: ["user/get_teams"]
    }),
    scores() {
      let scores = [];
      this.everyTeamMembers.forEach(element => {
        scores.push([
          element.sequence,
          element.precision,
          element.technical_reasoning,
          element.confluence
        ]);
      });
      return scores;
    },
    teamChart() {
      // return this.teamChartTwo;
      let data = {};
      data.labels = [
        "Sequence",
        "Precision",
        "Technical Reasoning",
        "Confluence"
      ];
      // data.series = this.handlerScores;
      data.series = this.scores;
      return data;
    }
  },
  methods: {
    handlerTeamData(id) {
      this.$store.dispatch("user/every_team_members", id).then(response => {
        this.everyTeamData = response;
        this.team_id = id;
        this.everyTeamMembers = response.team_users;
        // this.$refs.chart.updateChart(this.scores);

        // this.handlerScores
        // let scores = [];
        // this.everyTeamMembers.forEach(element => {
        //   this.handlerScores.push([
        //     element.sequence,
        //     element.precision,
        //     element.technical_reasoning,
        //     element.confluence
        //   ]);
        // });
        // let data = {
        //   labels: [
        //     "Sequence",
        //     "Precision",
        //     "Technical Reasoning",
        //     "Confluence"
        //   ],
        //   series: this.handlerScores
        // };
        // this.teamChartTwo = data;
      });
    },
    saveStatements() {
      this.$refs["personalStatementsRef"].validate(valid => {
        if (valid && this.team_id != null) {
          this.$store
            // add_team_statement_for_member
            .dispatch("user/add_team_statement_for_member_working_together", {
              team_id: this.team_id,
              user_id: this.auth().id,
              statement: this.user.statement
              // sequence: this.user.sequence_statement,
              // confluence: this.user.confluence_statement,
              // precision: this.user.precision_statement,
              // technical_reasoning: this.user.technical_reasoning_statement
            })
            .then(_ => {
              this.handlerTeamData(this.team_id);
              this.team_id = null;
              this.member_id = null;
              this.user.statement = null;
              // this.user.confluence_statement = null;
              // this.user.precision_statement = null;
              // this.user.technical_reasoning_statement = null;
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        } else {
          Swal.fire({
            title: "",
            icon: "warning",
            text: `Please choose team and enter statement`,
            confirmButtonText: `${this.localization("Ok")}`,
            type: "success",
            confirmButtonClass: "btn btn-success ",
            buttonsStyling: false
          });
        }
      });
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-collapse-item__header {
  height: auto;
  cursor: pointer;
  line-height: 38px;
}
.the_main_wrapper_for_charts {
  // border-bottom: 1px solid #ccc;
  .is_chart_wrapper {
    position: relative;
  }
  .lines_in_chart {
    position: relative;
    bottom: 240px;
    padding: 0 5px;
    &::before {
      position: absolute;
      content: "";
      width: 87%;
      left: 5%;
      bottom: 0;
      border: 1px dashed #11bfe3;
    }
    &.avoid {
      &::before {
        bottom: -20px;
      }
      .is_text_chart {
        position: absolute;
        bottom: -75px;
        font-size: 12px;
      }
    }
    &.needed {
      bottom: 324px;
      &::before {
        bottom: -45px;
      }
      .is_text_chart {
        position: absolute;
        bottom: -75px;
        font-size: 12px;
      }
    }
    &.first {
      bottom: 443px;
      &::before {
        display: none;
      }
      .is_text_chart {
        position: absolute;
        bottom: -130px;
        font-size: 12px;
      }
    }
  }
}
.working_together_wrapper {
  .list_of_new_statements_team {
    .every_list_of_new {
      margin-top: 10px;
      padding: 15px;
      border-radius: 5px;
      .is_state_text {
        margin-top: 10px;
        color: #777;
        line-height: 1.7;
        font-size: 16px;
      }
    }
  }
  .team_routes_wrapper {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-top: 20px;
    .back_link {
      color: #777;
      background-color: #eee;
      padding: 8px 10px;
      display: block;
      width: fit-content;
      margin: 10px 0;
      border-radius: 8px;
      transition: 0.3s;
      &:hover {
        background-color: #11bfe3;
        color: #fff;
      }
    }
  }
  .notes_text {
    color: rgba(#777, 0.7);
    font-size: 13px;
    font-style: italic;
  }
  // Start Header And All Sections Style
  .card-title {
    color: #777;
    font-size: 17px !important;
    .team_icon {
      color: #23ccef;
      margin-right: 10px;
    }
  }
  .decs_text {
    color: #777;
    font-size: 15px;
  }
  .importance_wrapper {
    color: #777;
    margin-bottom: 3rem;
    .main_title_from_cms {
      margin-top: 10px;
      // background: #23ccef;
      // p {
      //   font-size: 17px !important;
      // }
    }
    ol {
      li {
        margin-bottom: 10px;
        line-height: 1.7;
      }
    }
  }
  .dialog_footer {
    display: flex;
    justify-content: end;
    align-items: center;
    .save_button {
      background-color: #23ccef;
      border-color: #23ccef;
      min-width: 150px;
      color: #fff;
    }
  }
  // LCI Scores And Team Members
  .team_members_wrapper {
    .name_container {
      width: 200px;
    }
    .team_members {
      display: flex;
      align-items: center;
    }
  }

  .with_border {
    padding-top: 10px;
    border-top: 1px solid #ebebeb;
    margin-top: 20px;
  }

  .wrapper_collapse_for_header {
    display: flex;
    justify-content: space-between;
    width: 95%;
    .head_part_one {
      .part_a {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #777;
        .user_icon {
          &.admin_badge {
            color: #3c3;
          }
        }
      }
      .part_b {
        color: #11bfe3;
      }
    }
    .head_part_two {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  // LCI Scores
  .main-scores-container {
    // @media (min-width: 320px) and (max-width: 991px) {
    //   margin-top: 15px;
    // }
    padding-top: 20px;
    .every-score {
      gap: 7px;
      .score-word {
        color: #777;
      }
      .label {
        padding: 0.5rem;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;
        color: #fff;
        &.precision {
          background-color: #3c3;
        }

        &.technical_reasoning {
          background-color: #f93;
        }

        &.sequence {
          background-color: #39c;
        }

        &.confluence {
          background-color: #c36;
        }
      }
    }
  }
  // Start Introduce Yourself
  .introduce_yourself_wrapper {
    margin-top: 10px;
    border-top: 1px solid #ccc;
    .every_intro {
      padding: 0.6rem 0.5rem;
      border-bottom: 1px solid #ccc;
      .question_title {
        font-size: 19px;
        color: #6e6e6e;
        margin-bottom: 8px;
      }
      .patterns_wrapper {
        list-style: none;
        .pattern_title {
          font-size: 18px;
          margin-bottom: 8px;
        }
        .intro_list {
          .every_item {
            color: #777;
            line-height: 1.7;
            font-size: 16px;
            margin-bottom: 8px;
          }
        }
        .for_avoid_etc_words {
          color: #777;
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px;
          position: relative;
        }
      }
    }
  }
  .statements_wrapper {
    margin-top: 10px;
    // border-top: 1px solid #ccc;
    .just_for_wrapper_icon {
      // background-color: #eee;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .part_two_icons {
        min-width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .all_statements {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-grow: 1;
      border-bottom: 1px solid #ccc;
      .every_statement {
        // margin-top: 15px;
        padding: 0.6rem 0.5rem;
        .state_name {
          font-size: 18px;
          // margin-bottom: 8px;
          // &.sec {
          //   color: #11bfe3;
          // }
          &.per {
            color: #3c3;
          }
          &.tec {
            color: #f93;
          }
          &.con {
            color: #c36;
          }
        }
        .state_words {
          color: #777;
          line-height: 1.7;
          font-size: 16px;
        }
      }
    }
    .without_statement {
      // margin-top: 20px;
      font-weight: bold;
      .st_words {
        padding: 5px 0 0 0;
        color: #777;
        line-height: 1.7;
        text-align: center;
        font-size: 16px;
      }
    }
  }
  .for_line_graph_members_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 0;
    flex-wrap: wrap;
    .every_bullet_wrapper {
      display: flex;
      align-items: center;
      gap: 3px;
      margin-bottom: 20px;
      .bullet {
        background: #eee;
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
      &.member_1 {
        .bullet {
          background-color: black;
        }
      }
      &.member_2 {
        .bullet {
          background-color: #fe9d52;
        }
      }
      &.member_3 {
        .bullet {
          background-color: #4198d7;
        }
      }
      &.member_4 {
        .bullet {
          background-color: brown;
        }
      }
      &.member_5 {
        .bullet {
          background-color: #7a61ba;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.just_label_in_cards_override {
  .card label {
    text-transform: capitalize;
  }
  .build_with_rtl {
    .el-form-item__label {
      width: 100%;
    }
    .el-input__inner {
      text-align: right;
    }
  }
}
.working_together_wrapper {
  .ct-series-a .ct-line,
  .ct-series-a .ct-point {
    stroke: black;
  }
  .ct-series-b .ct-line,
  .ct-series-b .ct-point {
    stroke: #fe9d52;
  }
  .ct-series-c .ct-line,
  .ct-series-c .ct-point {
    stroke: #4198d7;
  }
  .ct-series-d .ct-line,
  .ct-series-d .ct-point {
    stroke: brown;
  }
  .ct-series-e .ct-line,
  .ct-series-e .ct-point {
    stroke: #7a61ba;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .ct-label.ct-horizontal.ct-end {
      font-size: 10px !important;
    }
  }
  .is_chart_wrapper {
    .ct-chart-line {
      width: 89% !important;
    }
  }
}
</style>
